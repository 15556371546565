import { Routes } from '@angular/router';
import { authGuard } from './login/guard/auth.guard';

export const routes: Routes = [
  {
    path: '',
    canActivateChild: [authGuard],
    loadComponent: () => import('./layout/layout.component').then((c) => c.LayoutComponent),
    children: [
      { path: '', redirectTo: '/merchants', pathMatch: 'full' },
      {
        path: 'merchants',
        loadComponent: () =>
          import('./merchants/merchants.component').then((c) => c.MerchantsComponent),
      },
      {
        path: 'merchant-form',
        loadComponent: () =>
          import('./merchants/merchant-form/merchant-form.component').then(
            (c) => c.MerchantFormComponent,
          ),
      },
      {
        path: 'categories',
        loadComponent: () =>
          import('./categories/categories.component').then((c) => c.CategoriesComponent),
      },
      {
        path: 'reports',
        loadComponent: () => import('./reports/reports.component').then((c) => c.ReportsComponent),
      },
      {
        path: 'transactions',
        loadComponent: () =>
          import('./transactions/transactions.component').then((c) => c.TransactionsComponent),
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.component').then((c) => c.LoginComponent),
  },
];
