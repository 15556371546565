import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { LoginService } from '../service/login.service';

export function tokenInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const platformId = inject(PLATFORM_ID);
  if (isPlatformBrowser(platformId)) {
    const loginService = inject(LoginService);
    const router = inject(Router);
    const accessToken = localStorage.getItem('accessToken');

    req = req.clone({
      setHeaders: {
        tenantId: 'com.shop.win',
        ...(req.url.includes('uploadFiles') ? {} : { 'Content-Type': 'application/json' }),
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
    });

    return next(req).pipe(
      catchError((err) => {
        if (err.status === 401 && !req.url.includes('/authenticate/')) {
          return loginService.refreshToken().pipe(
            switchMap(({ result: { accessToken } }) => {
              req = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              return next(req);
            }),
            catchError((err) => {
              if (err.error?.status == 'WRONG_TOKEN') {
                router.navigate(['login']);
              }
              return throwError(() => err);
            }),
          );
        }
        return throwError(() => err);
      }),
    );
  }
  return next(req);
}
