import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';

export const authGuard: CanActivateChildFn = () => {
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId)) {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      const router = inject(Router);
      return router.parseUrl('/login');
    }
  }

  return true;
};
